import {
	Accordion as ChakraAccordion,
	AccordionButton,
	AccordionItem as ChakraAccordionItem,
	AccordionItemProps as ChakraAccordionItemProps,
	AccordionPanel,
	AccordionProps as ChakraAccordionProps,
	CheckboxProps,
	chakra,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { PlusMinus } from "src/icons/PlusMinus";
import { Hx } from "../Heading/Heading";

export const FaqAccordion: React.FC<ChakraAccordionProps> = ({
	children,
	...rest
}) => {
	return (
		<ChakraAccordion
			allowMultiple
			variant="faq"
			defaultIndex={[]} // this fixes the warning: If 'allowMultiple' is passed, then 'index' or 'defaultIndex' must be an array. You passed: undefined,
			{...rest}
		>
			{children}
		</ChakraAccordion>
	);
};

type FaqAccordionItemProps = {
	label: string;
	number?: string;
	subline?: string;
	checkboxIsDisabled?: CheckboxProps["isDisabled"];
	children: React.ReactNode;
} & ChakraAccordionItemProps &
	Pick<CheckboxProps, "isRequired" | "value">;

export const FaqAccordionItem: React.FC<FaqAccordionItemProps> = ({
	label: labelText,
	number,
	isRequired,
	value,
	subline,
	children,
	...rest
}) => {
	const styles = useMultiStyleConfig("FaqAccordion", {});

	return (
		<ChakraAccordionItem {...rest}>
			{({ isExpanded }) => (
				<chakra.div
					__css={styles.contentWrapper}
					className={isExpanded ? "expanded" : ""}
				>
					<Hx>
						<AccordionButton
							display="flex"
							justifyContent="start"
							color="brand.blue"
						>
							<chakra.span __css={styles.label}>
								{number && number.length > 0 && (
									<chakra.span __css={styles.number}>
										{number}
									</chakra.span>
								)}
								<chakra.span textAlign="start">
									{labelText}
								</chakra.span>
							</chakra.span>
							<PlusMinus
								isActive={isExpanded}
								__css={styles.toggleIcon}
							/>
						</AccordionButton>
					</Hx>
					<AccordionPanel>{children}</AccordionPanel>
				</chakra.div>
			)}
		</ChakraAccordionItem>
	);
};

// 🔬 TBD: Please evaluate
