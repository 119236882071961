"use client";

import { chakra, Container, Link, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { HeadingLevelBoundary } from "src/components/Heading/Heading";
import { NextLink } from "src/components/NextLink";
import { useTranslatedString } from "src/i18n/hooks";
import { Calculator } from "src/icons";
import { ProductPageProps } from "src/lib/cms/products";
import { PremiumCalcButton } from "../PremiumCalcModal/PremiumCalcButton";
import { Product, Products } from "../Product/Product";
import { isStructuredText } from "../StructuredText/StructuredText";

export const createProductIdAndHref = (
	productSlug: string,
	productFamilySlug?: string,
	targetGroupSlug?: string,
	hasGroup = false,
) => {
	const parts = [productFamilySlug, productSlug];

	if (targetGroupSlug && !hasGroup) {
		parts.push(targetGroupSlug);
	}

	return {
		id: parts.join("_"),
		// href doesn't include a leading slash as we sometimes give this href to a component that already includes it
		// (see ProductOverviewItem, HeaderDropdownItem)
		href: `${parts.join("/")}`,
	};
};

export const ProductGroupPageContent: React.FC<
	Pick<NonNullable<ProductPageProps>, "products" | "insuranceProductFamily">
> = ({ products, insuranceProductFamily }) => {
	const styles = useMultiStyleConfig("ProductGroupPageContent");
	const t = useTranslatedString();

	return (
		<>
			<HeadingLevelBoundary>
				<chakra.div sx={styles.wrapper}>
					<Container maxW="container.xl">
						<Products>
							{products.map(
								({
									currentVersion,
									name,
									slug,
									targetGroup,
									icon,
								}) => {
									const facts = currentVersion.overviewFacts;
									const price = currentVersion.price;
									const priceDescription =
										currentVersion.priceDescription;

									const { id, href } = createProductIdAndHref(
										slug,
										insuranceProductFamily.slug,
										targetGroup?.slug,
									);

									return (
										<Product
											key={id}
											description={
												isStructuredText(facts)
													? facts
													: undefined
											}
											startingPrice={price ?? 0}
											priceDescription={priceDescription}
											title={
												targetGroup
													? targetGroup.name
													: name
											}
											icon={icon}
											headerAlignment="center"
										>
											<chakra.div marginTop="auto">
												<PremiumCalcButton
													insuranceProductSlug={slug}
													targetGroupSlug={
														targetGroup?.slug
													}
													id={`product_overview_premium_calc__${id}`}
													leftIcon={<Calculator />}
													mb={4}
													size="lg"
												/>

												{targetGroup && (
													<NextLink
														href={`/${href}`}
														passHref
													>
														<Link
															variant="outline"
															id={`product_overview_show_details__${id}`}
														>
															{t(
																"productGroupPageContent.detailsCta",
															)}
														</Link>
													</NextLink>
												)}
											</chakra.div>
										</Product>
									);
								},
							)}
						</Products>
					</Container>
				</chakra.div>
			</HeadingLevelBoundary>
		</>
	);
};

// 🔬 TBD: e2e
