import { ArrowUpIcon } from "@chakra-ui/icons";
import { Button, VisuallyHidden, useMultiStyleConfig } from "@chakra-ui/react";
import { ComponentProps, useCallback } from "react";
import { useTranslatedString } from "src/i18n/hooks";

type ScrollButtonProps = Omit<ComponentProps<typeof Button>, "id" | "onClick">;

export const ScrollButton: React.FC<ScrollButtonProps> = ({ sx, ...props }) => {
	const t = useTranslatedString();
	const styles = useMultiStyleConfig("ScrollButton");

	const scrollUp = useCallback(() => {
		if (typeof window !== "undefined") {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}
	}, []);

	return (
		<Button
			id="click_scroll_up"
			onClick={scrollUp}
			sx={{ ...sx, ...styles.button }}
			{...props}
		>
			<VisuallyHidden>{t("scrollUp")}</VisuallyHidden>
			<ArrowUpIcon sx={styles.arrow} />
		</Button>
	);
};

// 🔬 e2e: scroll-button.spec.ts
