import {
	chakra,
	useMultiStyleConfig,
	Container,
	useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import { ModularContentVideoCollectionFragment } from "src/__generated__/cms-schema.codegen";
import { DatoRichTextContent } from "src/components/Dato/DatoRichTextContent";
import { Hx } from "src/components/Heading/Heading";
import {
	StructuredText,
	StructuredTextData,
} from "src/components/StructuredText/StructuredText";
import {
	TabsOrColumnsPanel,
	TabsOrColumnsPanelMobileTablist,
	TabsOrColumns,
	TabsOrColumnsPanels,
	TabsOrColumnsDesktopTitle,
	desktopBreakpoint,
} from "src/components/TabsOrColumns/TabsOrColumns";
import { VideoPlayerTrigger } from "src/components/VideoPlayer/VideoPlayerTrigger";

type Column = ModularContentVideoCollectionFragment["columns"][number];

export type VideoCollectionSectionProps = {
	intro?: StructuredTextData;
	columns: Array<Column>;
};

export const VideoCollectionSection: React.FC<VideoCollectionSectionProps> = ({
	intro,
	columns,
}) => {
	const isTabbed = useBreakpointValue({
		base: true,
		[desktopBreakpoint]: false,
	});

	const styles = useMultiStyleConfig("VideoCollectionSection", {
		desktopBreakpoint,
		isTabbed,
	});

	return (
		<chakra.div __css={styles.wrapper}>
			<Container maxW="container.xl">
				{intro && (
					<chakra.div sx={styles.intro}>
						<StructuredText data={intro} />
					</chakra.div>
				)}

				<TabsOrColumns
					columns={columns.map((c) => ({ id: c.id, title: c.title }))}
				>
					<TabsOrColumnsPanelMobileTablist columns={columns} />

					<TabsOrColumnsPanels>
						{columns.map((column, index) => (
							<TabsOrColumnsPanel
								key={column.id}
								{...column}
								data-column-index={index}
							>
								<TabsOrColumnsDesktopTitle
									title={column.title}
									marginBottom={16}
								/>

								<chakra.div __css={styles.items}>
									{column.items.map((item) => (
										<chakra.div
											key={item.id}
											__css={styles.item}
										>
											<Hx size="h4" sx={styles.itemTitle}>
												{item.title}
											</Hx>
											{item.subline && (
												<DatoRichTextContent
													html={item.subline}
													sx={styles.subline}
												/>
											)}
											<VideoPlayerTrigger
												name={item.title}
												poster={
													item.thumbnail ?? undefined
												}
												autoPosterSrc={
													item.video.video
														.thumbnailUrl
												}
												videoSrc={
													item.video.video
														.streamingUrl
												}
											/>
										</chakra.div>
									))}
								</chakra.div>
							</TabsOrColumnsPanel>
						))}
					</TabsOrColumnsPanels>
				</TabsOrColumns>
			</Container>
		</chakra.div>
	);
};

// 🔬 skip
