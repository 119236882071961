import {
	Button,
	chakra,
	Container,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import { useParams } from "next/navigation";
import React from "react";
import { BlogpostTeaser } from "src/components/Blogpost/BlogpostTeaser";
import { Loading } from "src/components/Loading/Loading";
import {
	StructuredText,
	StructuredTextData,
} from "src/components/StructuredText/StructuredText";
import {
	TopicSelector,
	useTopicSelector,
} from "src/components/TopicSelector/TopicSelector";
import { useTranslatedString } from "src/i18n/hooks";
import { ArrowRight } from "src/icons";
import { usePagination } from "src/lib/hooks";
import { useGetBlogpostsFromCMS } from "./utils";

type BlogpostSectionProps = {
	topicSelector: boolean;
	topicSelectorText: string | null;
	intro: StructuredTextData | null;
};

export const BlogpostSection: React.FC<BlogpostSectionProps> = ({
	topicSelector: hasTopicSelector,
	topicSelectorText,
	intro,
}) => {
	const styles = useMultiStyleConfig("BlogpostsSection");
	const t = useTranslatedString();
	const params = useParams();
	const brandSlug = params.brandSlug;

	const { isLoading, blogposts } = useGetBlogpostsFromCMS(brandSlug);

	const { allTopics, currentTopic, setCurrentTopic } = useTopicSelector(
		blogposts.map(
			({
				productFamily: {
					name,
					slug,
					color: { hex },
				},
			}) => ({
				name,
				slug,
				color: hex,
			}),
		),
	);

	const blogpostsFiltered = blogposts.filter((b) =>
		currentTopic === t("blogpost.allTopics.slug")
			? b
			: b.productFamily.slug === currentTopic,
	);

	const { hasMore, onShowNext, onShowLess, sliceItems } = usePagination({
		itemsPerPage: 3,
		itemsTotal: blogpostsFiltered.length,
		breakpoint: "lg",
		overwriteMobileRule: true,
		cutoffThreshold: 0,
	});

	if (!blogposts.length) {
		return null;
	}

	return (
		<chakra.div bg="brand.lighter">
			<Container sx={styles.wrapper}>
				{intro && (
					<chakra.div __css={styles.intro}>
						<StructuredText data={intro} />
					</chakra.div>
				)}

				{isLoading ? (
					<Loading />
				) : (
					<>
						{allTopics.length > 2 && hasTopicSelector && (
							<TopicSelector
								topicSelectorText={
									topicSelectorText ??
									t("blogpost.chooseTopic")
								}
								currentTopic={currentTopic}
								setCurrentTopic={setCurrentTopic}
								allTopics={allTopics}
							/>
						)}

						<chakra.div sx={styles.blogpostsWrapper}>
							{blogpostsFiltered
								.slice(0, sliceItems)
								.map((blogpost) => (
									<BlogpostTeaser
										key={blogpost.id}
										blogpost={blogpost}
									/>
								))}
						</chakra.div>
					</>
				)}

				{hasMore ? (
					<Button
						variant="link"
						fontWeight="500"
						id="show_more_posts"
						onClick={onShowNext}
						rightIcon={<ArrowRight transform="rotate(90deg)" />}
					>
						{t("blogpost.seeMore")}
					</Button>
				) : (
					<Button
						variant="link"
						fontWeight="500"
						id="show_less_posts"
						onClick={onShowLess}
						rightIcon={<ArrowRight transform="rotate(-90deg)" />}
					>
						{t("blogpost.seeLess")}
					</Button>
				)}
			</Container>
		</chakra.div>
	);
};

// 🔬 TBD: Please evaluate
