import {
	chakra,
	ChakraProps,
	Image,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import { backgroundGradient } from "src/styles/theme";

export type BackgroundShapeProps = ChakraProps & {
	offset?: number;
	className?: string;
	variant?: "withGradient" | "whiteBackground";
};

/**
 * change the offset (approx. 0-100) to move the shape along the X/Y axis
 */
export const BackgroundShape: React.FC<BackgroundShapeProps> = ({
	offset = 50,
	variant,
	...props
}) => {
	const styles = useMultiStyleConfig("BackgroundShape", { variant });

	return (
		<chakra.div
			__css={styles.wrapper}
			style={{
				"--background-shape-offset": offset,
			}}
			bgGradient={backgroundGradient}
			{...props}
		>
			<Image
				src="/img/background-shape.svg"
				sx={styles.svg}
				alt="" // purely decorative
			/>
		</chakra.div>
	);
};

// 🔬 TBD: Please evaluate
