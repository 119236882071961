import { Divider, chakra, useMultiStyleConfig } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
	CMSLinkButton,
	CMSLinkButtonProps,
} from "src/components/CMSButton/CMSLinkButton";
import { useTranslatedString } from "src/i18n/hooks";

type Link = CMSLinkButtonProps;

export type NavigationSectionProps = { links: Array<Link> };

const NavigationItem: React.FC<CMSLinkButtonProps> = ({ ...props }) => {
	const [isActive, setIsActive] = useState(false);

	useEffect(() => {
		const onHashChange = () => {
			setIsActive(window.location.hash === props.jumpMark);
		};

		onHashChange();

		window.addEventListener("hashchange", onHashChange);

		return () => {
			window.removeEventListener("hashchange", onHashChange);
		};
	}, [props.jumpMark]);

	const styles = useMultiStyleConfig("NavigationSection", { isActive });

	return (
		<chakra.div __css={styles.linkWrapper}>
			<CMSLinkButton {...props} sx={styles.link} />
		</chakra.div>
	);
};

export const NavigationSection: React.FC<NavigationSectionProps> = ({
	links,
}) => {
	const styles = useMultiStyleConfig("NavigationSection");
	const t = useTranslatedString();

	return (
		<chakra.div __css={styles.wrapper}>
			<chakra.div __css={styles.container}>
				<span>{t("tableOfContents")}</span>
				<chakra.div __css={styles.links}>
					{links.map(({ ...props }) => (
						<NavigationItem key={props.id} {...props} />
					))}
				</chakra.div>
			</chakra.div>
			<Divider borderWidth={1} borderColor="brand.light" />
		</chakra.div>
	);
};

// 🔬 e2e: story
