import { chakra } from "@chakra-ui/react";

export const Email: React.FC<{ email: string }> = ({ email }) => {
	return (
		<chakra.div
			position="relative"
			left="50%"
			transform="translateX(-50%)"
			w="fit-content"
			borderRadius="md"
			bg="brand.light"
			py={2}
			px={24}
			fontWeight="700"
			color="brand.blue"
		>
			{email}
		</chakra.div>
	);
};

export const Heading: React.FC<{ left: string; right: string }> = ({
	left,
	right,
}) => {
	return (
		<chakra.div
			display="flex"
			gap={6}
			alignItems="center"
			color="brand.blue"
		>
			<chakra.span fontSize="3rem" fontWeight="bold">
				{left}
			</chakra.span>
			<chakra.span fontSize="1.125rem" fontWeight="semibold">
				{right}
			</chakra.span>
		</chakra.div>
	);
};

// 🔬 TBD
