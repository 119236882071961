import {
	Button as ChakraButton,
	ButtonProps,
	chakra,
	Container,
	SimpleGrid,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { BubbleContainer } from "src/components/BubbleContainer/BubbleContainer";
import { Flipper, FlipperProps } from "src/components/Flipper/Flipper";
import {
	StructuredText,
	StructuredTextData,
} from "src/components/StructuredText/StructuredText";
import { useTranslatedString } from "src/i18n/hooks";
import { ChevronUp } from "src/icons";
import { usePagination } from "src/lib/hooks";
import { useBubbleContainerContext } from "../renderSections";

export type FlipperSectionProps = {
	flippers: Array<FlipperProps & { id: string }>;
	intro?: StructuredTextData;
	limit?: number;
};

const duration = 0.3;

const Button: React.FC<ButtonProps> = ({ children, ...props }) => (
	<ChakraButton variant="link" rightIcon={<ChevronUp />} {...props}>
		{children}
	</ChakraButton>
);

export const FlipperSection: React.FC<FlipperSectionProps> = ({
	flippers,
	intro,
	limit,
}) => {
	const styles = useMultiStyleConfig("FlipperSection");
	const t = useTranslatedString();

	const bubbleProps = useBubbleContainerContext();

	const {
		isMobile,
		hasMore,
		onShowNext,
		onShowLess,
		sliceItems,
		remainingItems,
	} = usePagination({
		itemsPerPage: limit,
		itemsTotal: flippers.length,
		breakpoint: "lg",
	});

	return (
		<BubbleContainer {...bubbleProps}>
			<Container maxW="container.lg" sx={styles.wrapper}>
				{intro && (
					<chakra.div __css={styles.intro}>
						<StructuredText data={intro} />
					</chakra.div>
				)}

				<SimpleGrid
					columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
					spacing={{ base: 8, md: 10 }}
					padding={{ base: 8, md: 10 }}
					as="ul"
					sx={styles.grid}
				>
					<AnimatePresence initial={false}>
						{flippers.slice(0, sliceItems).map((flipper, i) => (
							<motion.li
								key={flipper.id}
								variants={{
									initial: {
										opacity: 0,
										translateY: -40,
										originY: 0,
									},
									animate: {
										opacity: 1,
										translateY: 0,
										originY: 0,
									},
								}}
								initial="initial"
								animate="animate"
								transition={{ duration }}
							>
								<Flipper {...flipper} />
							</motion.li>
						))}
					</AnimatePresence>
				</SimpleGrid>

				{isMobile && (
					<>
						{hasMore ? (
							<Button
								id="show_more_flippers"
								onClick={onShowNext}
								sx={styles.moreButton}
							>
								{t("seeMore", { count: remainingItems })}
							</Button>
						) : (
							<Button
								id="show_less_flippers"
								onClick={onShowLess}
								sx={styles.lessButton}
							>
								{t("seeLess")}
							</Button>
						)}
					</>
				)}
			</Container>
		</BubbleContainer>
	);
};

// 🔬 skip
