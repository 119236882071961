import {
	chakra,
	ChakraProps,
	HStack,
	useMultiStyleConfig,
	LinkBox,
	LinkOverlay,
} from "@chakra-ui/react";
import {
	InlineLinkButtonFragment,
	InlineLinkButtonRecord,
} from "src/__generated__/client.codegen";
import { DatoAsset, DatoAssetData } from "src/components/Dato/DatoAsset";
import { DatoRichTextContent } from "src/components/Dato/DatoRichTextContent";
import { HeadingLevelBoundary, Hx } from "src/components/Heading/Heading";
import { useSectionNavigatorContext } from "src/components/SectionNavigator/SectionNavigator";
import { useTranslatedString } from "src/i18n/hooks";
import { ArrowRightLong } from "src/icons";
import { useGTMJumpToProducts } from "src/lib/gtm";
import { getAnalyticsId } from "src/lib/utils.esm";
import { OfferLink } from "./OfferLink";

const isInlineLinkButtonRecord = (
	obj: unknown,
): obj is InlineLinkButtonRecord =>
	typeof obj === "object" &&
	obj !== null &&
	"__typename" in obj &&
	obj.__typename === "InlineLinkButtonRecord";

export type OfferProps = ChakraProps & {
	icon: DatoAssetData;
	title: string;
	text?: string;
	link?: Array<InlineLinkButtonFragment>;
};

export const Offer: React.FC<OfferProps> = ({
	icon,
	title,
	text,
	link,
	...rest
}) => {
	const linkButton = link?.find(isInlineLinkButtonRecord);

	/* TODO this code up to "styles" as well as the "Link" component could be removed at one point
	if all offers have a set link button */
	const t = useTranslatedString();
	const trackProductClick = useGTMJumpToProducts();

	const buttonText = t("offer.readMore");

	const { allSections } = useSectionNavigatorContext();
	const productComparisonIndex = allSections.findIndex(
		(enhanced) => enhanced.section.__typename === "ProductComparisonRecord",
	);
	const productComparisonAnchor =
		productComparisonIndex >= 0
			? (allSections[productComparisonIndex]?.anchorId ?? "")
			: "";

	const styles = useMultiStyleConfig("Offer");

	return (
		<chakra.div __css={styles.wrapper} {...rest}>
			<HeadingLevelBoundary>
				<LinkBox
					as={HStack}
					alignItems="flex-start"
					spacing={6}
					sx={styles.inner}
				>
					<DatoAsset
						alt="" // purely decorative icon
						as="div"
						data={icon}
						sx={styles.icon}
					/>
					<chakra.div __css={styles.right}>
						<Hx size="h3" as="h2" sx={styles.heading}>
							{title}
						</Hx>

						{text && (
							<DatoRichTextContent html={text} sx={styles.text} />
						)}
						{linkButton ? (
							<OfferLink linkData={linkButton} />
						) : productComparisonAnchor === "" ? null : (
							<LinkOverlay
								id={getAnalyticsId(`offer_details_jump`, title)}
								onClick={() => trackProductClick(buttonText)}
								href={`#${productComparisonAnchor}`}
								sx={styles.readMore}
							>
								{buttonText}
								<ArrowRightLong />
							</LinkOverlay>
						)}
					</chakra.div>
				</LinkBox>
			</HeadingLevelBoundary>
		</chakra.div>
	);
};

// 🔬 TBD: Please evaluate
